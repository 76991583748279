import { render, staticRenderFns } from "./collectionInfo.vue?vue&type=template&id=ab6b4354&scoped=true&"
import script from "./collectionInfo.vue?vue&type=script&lang=js&"
export * from "./collectionInfo.vue?vue&type=script&lang=js&"
import style0 from "./collectionInfo.vue?vue&type=style&index=0&id=ab6b4354&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab6b4354",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("F:\\haohao\\QW\\allnet_crm\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ab6b4354')) {
      api.createRecord('ab6b4354', component.options)
    } else {
      api.reload('ab6b4354', component.options)
    }
    module.hot.accept("./collectionInfo.vue?vue&type=template&id=ab6b4354&scoped=true&", function () {
      api.rerender('ab6b4354', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/order/after-sale/upgrade/components/collectionInfo.vue"
export default component.exports